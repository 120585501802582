import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";

import GoogleIcon from "../assets/icons/Google.svg";
import FacebookIcon from "../assets/icons/FB.svg";

import "../assets/styles/styles.scss";
import "../assets/styles/pages/login-page.scss";
import "../assets/styles/components/button.scss";
import "../assets/styles/components/input.scss";

interface User {
  email: string;
  password: string;
}

const Login: React.FC = (props) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [user, setUser] = useState<User>({ email: "", password: "" });
  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    setUser({
      email: email,
      password: password,
    });

    localStorage.setItem("user", JSON.stringify(user));

    setEmail("");
    setPassword("");

    console.log(user);

    return user;
  };

  useEffect(() => {
    if (email && password) {
      submitRef.current.disabled = false;
    } else {
      submitRef.current.disabled = true;
    }
  }, [email, password, user]);

  return (
    <article className="login">
      <header className="login__header">
        <p className="login__intro">
          Hi!
          <br />
          Welcome to volonteros!
        </p>
        <footer>
          <small className="login__copyright">
            © Urban Sport and Culture Volunteers
          </small>
        </footer>
      </header>
      <article className="login__main">
        <div className="login__content">
          <p>
            Already have an account? <Link to="/login">Log in here</Link>
          </p>
          <section>
            <h1 className="login__title">Register</h1>
            <form ref={formRef} onSubmit={handleSubmit} className="login__form">
              <div className="input__group" role="presentation">
                <label className="input__label" htmlFor="email">
                  Email Address
                </label>
                <input
                  className="input__field"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Type your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input__group" role="presentation">
                <label className="input__label" htmlFor="password">
                  Password
                </label>
                <input
                  className="input__field"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Type your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="login__buttons" role="presentation">
                <button
                  ref={submitRef}
                  type="submit"
                  className="btn btn--success btn--block"
                  disabled>
                  Register
                </button>
                {/* <Link
                  to="/forgot-password"
                  className="btn btn--ghost btn--block">
                  Forgot Password?
                </Link> */}
              </div>
            </form>
          </section>
          <footer className="login__footer">
            <p>Or you can Register using</p>
            <div className="login__icons" role="presentation">
              <GoogleIcon />
              <FacebookIcon />
            </div>
          </footer>
        </div>
      </article>
    </article>
  );
};

export default Login;
